
// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Vendors
import moment from "moment";

// Models
import {CoachPlayersProfileMatchesListIndex} from "@/models/app/coach/players/profile/matches/list/index"
import {AppCommonMatchCategory} from "@/models/app/common/match-category";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachPlayersProfileMatchesListFormCreateIndexVue from "@/views/app/coach/players/profile/matches/list/form/create.vue";
import CoachPlayersProfileMatchesListFormUpdateIndexVue from "@/views/app/coach/players/profile/matches/list/form/update.vue";
import CoachPlayersProfileMatchesListFormRemoveIndexVue from "@/views/app/coach/players/profile/matches/list/form/remove.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  props: [
    "matchCategories",
  ],
  components: {
    ElRow,
    ElCol,
    CoachPlayersProfileMatchesListFormCreateIndexVue,
    CoachPlayersProfileMatchesListFormUpdateIndexVue,
    CoachPlayersProfileMatchesListFormRemoveIndexVue,
  },
})
export default class CoachPlayersProfileMatchesListIndexVue extends Vue {
  isLoading: boolean | null = false;

  formData: any = {
    filter: {
      user_id: "*",
      match_category_id: "*",
      date: [
        moment(),
        moment().add(14, "days"),
      ],
    },
    organization_id: store.getters.getOrganizationMember.organization_id,
  };

  moment: any = null;

  matchCategories: Array<AppCommonMatchCategory> = [];

  matches: Array<CoachPlayersProfileMatchesListIndex> = [];

  async getMatches(formData: any = null): Promise<void> {
    this.isLoading = true;

    if (formData) {
      this.formData = formData;
    }

    await getRequest({
      uri: `/common/matches`,
      formData: this.formData,
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.matches = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    this.moment = moment;
    this.formData.filter.user_id = this.$route.params.id;
    await this.getMatches();
  }
}


// Vue
import {Options, Vue} from "vue-class-component";

// Store
import store from "@/store";

// Models
import {AppCommonMatchCategory} from "@/models/app/common/match-category";
import {ResponseIndex} from "@/models/response";

// Components
import {
  ElRow,
  ElCol,
} from "element-plus";
import CoachNavigationVue from "@/components/coach/navigation.vue";
import CoachPlayersProfileNavigationVue from "@/components/coach/players/profile/navigation.vue";
import CoachPlayersProfileMatchesFilterIndexVue from "@/views/app/coach/players/profile/matches/filter/index.vue";
import CoachPlayersProfileMatchesListIndexVue from "@/views/app/coach/players/profile/matches/list/index.vue";

// Services
import {getRequest} from "@/services/api/request";

@Options({
  components: {
    ElRow,
    ElCol,
    CoachNavigationVue,
    CoachPlayersProfileNavigationVue,
    CoachPlayersProfileMatchesFilterIndexVue,
    CoachPlayersProfileMatchesListIndexVue
  },
})
export default class CoachPlayersProfileMatchesIndexVue extends Vue {
  isLoading: boolean | null = false;

  matchCategories: Array<AppCommonMatchCategory> = [];

  $refs!: {
    CoachPlayersProfileMatchesListIndexVue: any;
  };

  getMatchesByFilter(formData: any): void {
    this.$refs.CoachPlayersProfileMatchesListIndexVue.getMatches(formData);
  }

  async getMatchCategories(): Promise<void> {
    this.isLoading = true;
    await getRequest({
      uri: `/common/utility/match-categories`,
      formData: {
        organization_id: store.getters.getOrganizationMember.organization_id,
      },
      isProtected: true,
    }).then((r: ResponseIndex) => {
      if (r.data) {
        this.matchCategories = r.data;
      }
    });
    this.isLoading = false;
  }

  async created(): Promise<void> {
    await this.getMatchCategories();
  }
}
